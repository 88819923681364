<template>
  <v-container>
    <div class="d-flex">
      <div class="display-1 mb-2 mr-auto">Services</div>
      <v-tooltip
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              @click="showDialog = true"
              v-bind="attrs"
              v-on="on"
              fab
              dark
              x-small
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add New Service</span>
        </v-tooltip>
      <new-fiware-service v-model="showDialog" :cancleDisable="false" @create="createFiwareService($event)"></new-fiware-service>
    </div>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="success"
    >
      <v-tab
        class="text-overline"
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item
        v-for="item in items"
        :key="item"
        :transition="false"
        :reverse-transition="false"
      >
        <v-card v-if="isSVFetch" flat class="mt-4">
          <v-simple-table
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Service
                  </th>
                  <th class="text-left">
                    Created By
                  </th>
                  <th class="text-left">
                    Timestamp
                  </th>
                  <th class="text-right">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(service, index) in fiwareServices[tab]"
                  :key="index"
                >
                  <td>{{ service.service }}</td>
                  <td>{{ service.createdBy }}</td>
                  <td>{{ service.timestamp }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      small
                      v-on:click.stop="confirm.active = true, confirm.id = service.id, confirm.type = 'approve'"
                      color="success"
                      v-if="tab === 1 && mepBackend.includes('mep-backend:admin') && service.status === 'Request'"
                    >
                      <v-icon color="lighten-1">mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      v-on:click.stop="confirm.active = true, confirm.id = service.id, confirm.type = 'delete'"
                      color="danger"
                      v-if="tab === 1"
                    >
                      <v-icon color="lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <loading-list v-if="!isSVFetch" />
      </v-tab-item>
    </v-tabs-items>
    <confirm-dialog v-model="confirm.active" :type="confirm.type" :title="confirm.id" @confirm="confirmDialog(confirm.id)"></confirm-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import api from '@/services/api'

export default {
  name: 'FiwareService',
  components: {
    NewFiwareService: () => import('@/components/fiware/NewFiwareService'),
    LoadingList: () => import('@/components/LoadingList'),
    // LoadingPage: () => import('@/components/LoadingPage'),
    // EmptyPage: () => import('@/components/EmptyPage'),
    ConfirmDialog: () => import('@/components/ConfirmDialog')
  },
  data: () => ({
    showDialog: false,
    fiwareServiceList: [],
    fiwareServiceReqList: [],
    fetchService: false,
    fetchServiceReq: false,
    confirm: {
      type: 'delete',
      active: false,
      id: null
    },
    tab: null,
    items: [
      'Services', 'Service Requests'
    ],
    isSVFetch: false
  }),
  methods: {
    fetchFiwareService: function () {
      Promise.all([api.get('/api/services', this.$store.state.fiwareService, null, this.$store.state.tokenRPT), api.get('/api/service-requests', this.$store.state.fiwareService, null, this.$store.state.tokenRPT)])
        .then(responses => {
          responses.forEach((resp, respIndex) => {
            const key = ['services', 'service-requests']
            let msg = this.globalToastFn(`Fetch: ${key[respIndex]}`, resp.statusText)
            if (resp.status === 200) {
              resp.json().then(json => {
                // console.log(json)
                if (respIndex === 0) {
                  this.fiwareServiceList = json.map(service => {
                    return Object.assign({}, { _id: service._id }, service._source)
                  })
                } else if (respIndex === 1) {
                  this.fiwareServiceReqList = json.map(service => {
                    return Object.assign({}, { _id: service._id }, service._source)
                  })
                }
              })
            } else {
              this.$toast.error(msg)
            }
            this.isSVFetch = true
          })
        })
    },
    createFiwareService: function (serviceName) {
      api.post('/api/service-requests', { service: serviceName }, null, null, this.$store.state.tokenRPT).then((response) => {
        let msg = this.globalToastFn(`Create: ${serviceName}`, response.statusText)
        if (response.status === 200) {
          this.$toast.success(msg)
          response.json().then((json) => {
            // console.log(json)
            this.fiwareServiceReqList.push(json)
          })
          this.tab = 1
        } else {
          this.$toast.error(msg)
        }
      })
    },
    confirmDialog: function (serviceId) {
      // console.log(objConfirm)
      if (this.confirm.type === 'delete') {
        api.delete(`/api/service-requests/${serviceId}`, null, null, this.$store.state.tokenRPT).then((response) => {
          let msg = this.globalToastFn(`Delete: ${serviceId}`, response.statusText)
          if (response.status === 200) {
            response.json().then((json) => {
              this.fiwareServiceReqList = this.fiwareServiceReqList.filter(fwService => fwService._id !== serviceId)
            })
            this.$toast.success(msg)
          } else {
            this.$toast.error(msg)
          }
        })
      } else if (this.confirm.type === 'approve') {
        const foundIndex = this.fiwareServiceReqList.findIndex(serviceReq => serviceReq._id === serviceId)
        this.fiwareServiceReqList[foundIndex] = Object.assign(this.fiwareServiceReqList[foundIndex], { status: 'Approved' })
        api.put(`/api/service-requests/${serviceId}`, null, null, null, this.$store.state.tokenRPT).then((response) => {
          let msg = this.globalToastFn(`Approve: ${serviceId}`, response.statusText)
          if (response.status === 200) {
            // this.fetchFiwareService()
            const foundIndex = this.fiwareServiceReqList.findIndex(serviceReq => serviceReq._id === serviceId)
            this.fiwareServiceReqList[foundIndex] = Object.assign(this.fiwareServiceReqList[foundIndex], { status: 'Approved' })
            this.$toast.success(msg)
          } else {
            this.$toast.error(msg)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['username', 'temp', 'mepBackend']),
    fiwareServices: function () {
      let fiwareServices = [this.fiwareServiceList, this.fiwareServiceReqList]
      let services = []
      fiwareServices.forEach(items => {
        let sv = items.map((services) => {
          let source = {
            id: services._id,
            timestamp: services['@timestamp'],
            createdBy: services.createdBy,
            service: services.service
          }
          if (services.status) source.status = services.status
          return source
        })
        services.push(sv)
      })
      return services
    }
  },
  watch: {
    '$store.state.fiwareService': function () {
      // console.log(this.$store.state.fiwareService)
      this.fetchFiwareService()
    }
  },
  created () {
    this.fetchFiwareService()
  }
}
</script>
